// extracted by mini-css-extract-plugin
export var column = "LocationProviderProductsSlider__column__FDpyT";
export var flex = "LocationProviderProductsSlider__flex__I8_mO";
export var flexColumn = "LocationProviderProductsSlider__flexColumn__LNhHJ";
export var gap1 = "LocationProviderProductsSlider__gap1__juFFT";
export var gap2 = "LocationProviderProductsSlider__gap2__jxxqu";
export var gap3 = "LocationProviderProductsSlider__gap3__CmZr_";
export var gap4 = "LocationProviderProductsSlider__gap4__oHaGI";
export var gap5 = "LocationProviderProductsSlider__gap5__AaDth";
export var row = "LocationProviderProductsSlider__row__bXWkE";
export var slickSliderOverride = "LocationProviderProductsSlider__slickSliderOverride__vdv8Y";
export var sliderContainer = "LocationProviderProductsSlider__sliderContainer__b2dyo";